import { graphql, PageProps } from "gatsby";
import React from "react";
import { Breadcrumb } from "../components/Breadcrumb";
import { Heading01 } from "../components/Heading01";
import { Layout } from "../components/Layout/index";
import { NewsList01 } from "../components/NewsList01";
import bgimgPc from "../images/h1/h1news.png";
import bgimgSp from "../images/h1/h1news_sp.png";

const NewsPage: React.VFC<PageProps<GatsbyTypes.IndexNewsQuery>> = ({
  data,
}) => {
  return (
    <Layout title="お知らせ" description="ミスギのお知らせページです。">
      <Heading01
        label="NEWS"
        smallLabel="お知らせ"
        bgimg={[bgimgPc, bgimgSp]}
      />
      <Breadcrumb labels={[{ url: "", text: "お知らせ" }]} />
      <div className="l-container-sm">
        <ul className="mt-8 mb-16 pc:mb-32">
          {data.allWpPost.nodes.map(
            (node) =>
              node.title && (
                <NewsList01
                  key={node.title}
                  date={node.date || "日付不明"}
                  news={node.title}
                />
              )
          )}
        </ul>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query IndexNews {
    allWpPost(sort: { fields: date, order: DESC }) {
      nodes {
        title
        date(formatString: "YYYY.MM.DD")
      }
    }
  }
`;

export default NewsPage;
